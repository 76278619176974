:root {
  --colour-main: #F2F2F2;
  --colour-navbar: #D4D7DF;
--colour-sub1: #764AF1;
--colour-sub2: #F32424;

background-color: var(--colour-main);
}

* {
  font-family: 'Outfit', sans-serif;
}

h1 {
  text-align: center;
  font-size: 3rem;
}

p {
  font-size: 1.25rem;
}

button {
  cursor: pointer;
  border: none;
  color: var(--colour-sub1);
  background-color: transparent;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App main {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 1500px;
  margin-left: 30px;
  margin-right: 30px;
}

.navbar {

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  background-color: var(--colour-navbar);
}

.navbar div {
  display: flex;
  flex-flow: row wrap;
  justify-content: right;
  margin-left: 20px;
}

.navbar a {
  text-decoration: none;
}

.navbar-links a {
  margin: 10px;
  font-size: 1.5rem;
  color: var(--colour-sub1);
}

.navbar-links a:hover {
  transform: scale(1.2, 1.2);
  font-weight: bold;
  color: var(--colour-sub2);
}

.navbar h1 {
  font-family: monoton, cursive;
  font-size: 3.5rem;
  font-weight: lighter;
  margin: 0px;
  margin-left: 10px;
  color: var(--colour-sub2);
  text-shadow: 3px 1px var(--colour-sub1);
  text-align: left;
}

.footer {
  justify-self: flex-end;
  display:flex;
  justify-content: center;
  background-color: var(--colour-sub1);
  margin-top: auto;
}

.footer img {
  height: 70px;
}

.footer div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px 10px 20px;
}

.footer h3 {
  margin: 0px;
}

/***************************************
PROJECT PAGE 
***************************************/

.project-page {

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.project-title {
  display: flex;
  align-items: center;
}

.project-title div {
  flex: 1;
}

.project-title button {
  text-align: left;
  font-size: 3rem;
  margin-left: 20px;
  margin-right: 20px;
  width: 50px;
}

.project-title button:hover {
  transform: scale(1.2, 1.2);
  color: var(--colour-sub2);
}

.project-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  margin: 30px;
}

.project-description button {
  font-size: 2rem;
  width: min-content;
  font-weight: bold;
  padding: 10px;
}

.project-description button:hover {
  transform: scale(1.2, 1.2);
  color: var(--colour-sub2);
  text-decoration: underline;
}

.project-links a {
  text-decoration: none;
  color: var(--colour-sub1);
  margin-right: 30px;
  font-size: 2rem;
}

.project-links a:hover {
  transform: scale(1.2, 1.2);
  font-weight: bold;
  color: var(--colour-sub2);
}

.screen-shots {
  display:flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.screen-shots div {
  max-width: 400px;
  margin: 20px;
  text-align: center;
  font-size: 1.25rem;
}

.screen-shots img {
  max-height: 400px;
  max-width: 400px;
  margin: 20px;
}

/***************************************
PROJECT THUMBNAILS
***************************************/

.project-thumbnail {

  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 3px;
  padding: 10px;
  width: 400px;
  text-align: center;
}

.project-thumbnail a {
  text-decoration: none;
  color: black;
}

.project-thumbnail img {

  max-width: 300px;
  max-height: 400px;
}

.project-thumbnail h3 {

  /* color: var(--colour-sub1); */
  font-size: 2rem;
}

.project-thumbnail div {

  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-thumbnail:hover {

  /* background-color: white; */
  /* transform: translate(10px, 10px); */
  transform: scale(1.1, 1.1);
  /* box-shadow: black -0px -0px 10px 2px; */
}

.project-thumbnail:hover h3{
  color: var(--colour-sub2);
  text-decoration: underline;
}

.tech-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.tech-item {
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 20px;
  text-decoration: none;
}

.tech-item:hover {
  transform: scale(1.05, 1.05);
  text-decoration: underline;
  color: var(--colour-sub2)
}

.tech-item img {
  max-width: 120px;
  max-height: 120px;
}

/***************************************
ABOUT
***************************************/
.about {
  display: flex;
  flex-direction: row;
}

.about div {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  margin: 30px;
  align-items: center;
}

.about img {
  max-height: 300px;
  border-radius: 50%;
}

/***************************************
CONTACT
***************************************/
.contact {
  text-align: center;
  margin: 30px;
}


@media only screen and (max-width: 450px) {

  .screen-shots img {
    max-width: 80vw;
  }

  .navbar h1 {
    font-size: 3rem;
  }

  .navbar a {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 800px) {

  .about {
      flex-direction: column-reverse;
    }
}